  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
  font-family: "Roboto Mono", "Helvetica Neue";
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.logo {
  width: 3.5rem;
}

nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
margin-right: 0.75rem;
margin-left: 0.75rem;
color: #282c34 !important;
border-top: 0.1875rem solid #0000 ;

}

/* .navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid #282c34;
} */

.navbar .navbar-nav .nav-link:hover {
background-color: #61dafb;
color: #0000;
}

.footer {
  flex: 1;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
