.privacy-policy {
    margin: 0 auto;
    max-width: 960px;
    padding: 2rem;
  }
  
  .privacy-policy h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.0375rem;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 1rem;
  }
  
  .privacy-policy li {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  

  